<template>
  <section
    id="partners"
  >
    <div class="row heading">
      <div class="col-md">
        <h2>Partners</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md">
        <p>
          The Labrador Straits Community is proud to partner with organizations
          that support our goals. If you would like to become a partner, please
          <a href="mailto: info@bettertogethersolutions.com">get in touch</a>.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md">
        <div class="card-deck w-50">
          <div class="card text-center">
            <div class="card-header">
              <h5>
                Better Together Solutions
              </h5>
            </div>
            <div class="card-footer h-100">
              <a
                href="https://bettertogethersolutions.com/?utm_source=labrador-straits-community&utm_medium=partner&utm_campaign=partnership"
                target="bts"
                title="Better Together Solutions"
              >
                <img
                  src="assets/images/partners/better-together-solutions-logo.png"
                  alt="Better Together Solutions"
                  class="card-img-top"
                >
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Partners',
}
</script>

<style scoped lang="scss">
  .card {
    > a {
    }
  }
</style>
